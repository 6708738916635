<template>
    <v-navigation-drawer 
        v-model="objTrackingSheet.trs_id" clipped :stateless="drawerStateless" fixed temporary right :width="rightDrawerWidth"
        class="grey lighten-3" style="z-index:1054;"><!-- stateless -->
        <v-toolbar dark dense :color="(element.state == 'modifier') ? 'red darken-4' : 'primary'" class="elevation-1 ">
            <v-toolbar-title >{{site_name}}</v-toolbar-title>
            <v-btn icon small :href="link.consult"><v-icon>{{(element.state == 'apercu') ? 'mdi-close' : 'mdi-arrow-left'}}</v-icon></v-btn>
<!--            <v-menu v-model="siteMenu" :close-on-content-click="false" :nudge-width="600" offset-y z-index="1100">
                <template v-slot:activator="{ on: menu }">
                <v-tooltip right open-delay="500">
                    <template v-slot:activator="{ on: tooltip }">
                        <v-btn v-on="{ ...tooltip, ...menu }" icon slot="activator">
                        <v-badge color="red" class="mt-1" :content="bookmarkCount" :value="bookmarkCount">
                            <v-icon>mdi-card-bulleted-outline</v-icon>
                        </v-badge>
                        </v-btn>
                    </template>
                    <span>Voir les sites en favoris</span>
                </v-tooltip>
                </template>
                <view-opencard class="mt-2" style="z-index:900 !important;" :tabsheetCount="bookmarkCount" :menuState="openCardState"></view-opencard>
            </v-menu>
-->
            <v-spacer></v-spacer>
            <v-btn rounded depressed v-show="(element.state == 'apercu')" color="blue" class="white--text" 
                :href="link.base + '/sheet/' + objTrackingSheet.trs_id + '/consulter'">
                <span class="hidden-sm-and-down">Consulter</span>
                <v-icon right class="hidden-md-and-up">mdi-pencil-plus</v-icon>
            </v-btn>
            <v-btn rounded depressed v-if="(element.state == 'consulter') && isAuthorized( 'trackingsheetedit', 'edit' )" color="green" class="white--text" 
                :href="link.base + '/sheet/' + objTrackingSheet.trs_id + '/modifier'">
                <span class="hidden-sm-and-down">Modifier</span>
                <v-icon right class="hidden-md-and-up">mdi-pencil-plus</v-icon>
            </v-btn>
            <!-- <v-btn rounded depressed v-show="(element.state == 'modifier')" color="green" class="white--text" >
                <span class="hidden-sm-and-down">Enregistrer</span>
                <v-icon right class="hidden-md-and-up">mdi-pencil-plus</v-icon>
            </v-btn> -->
            <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                    <v-btn 
                        x-small fab depressed :color="(bookmark['b-' + objTrackingSheet.trs_id]) ? 'amber darken-1' : 'grey darken-1'"
                        class="ma-1" @click="addToBookmark()" v-on="on"><v-icon>mdi-star</v-icon>
                    </v-btn>
                </template>
                <span>Ajout favoris</span>
            </v-tooltip>
            <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                    <v-btn x-small fab depressed color="light-green darken-1" class="ma-1" @click="leftDrawerMaximize()" v-on="on"><v-icon>mdi-window-maximize</v-icon></v-btn>
                </template>
                <span>{{ labels.windowSize }}</span>
            </v-tooltip>
            <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                    <v-btn x-small fab depressed color="red darken-1" class="ma-1" :href="link.base" v-on="on"><v-icon>mdi-close</v-icon></v-btn>
                </template>
                <span>Fermer</span>
            </v-tooltip>
        </v-toolbar>
        <!-- Composant dynamiques -->
        <router-view v-if="openRoute" name="content" :toast="toast" :infTrackingSheet="objTrackingSheet" :routeState="element"></router-view>
    </v-navigation-drawer>
</template>

<script>
import { trsObj }   from '../mixins/trs_obj.js'
//import vOpencard    from '../components/View_openedcard.vue'
import { cacheGetters, cacheActions, cacheMutations } from "../store/cache.module"
import { usrGetters }   from "../store/user.module"

export default {
    name: 'slider',
    props: ['toast'],
    mixins: [ trsObj ],
    data: function () {
        return {
            openRoute: true,
            labels: { windowSize: 'Agrandir' },
            tre_id: 0,
            activeBtn: 1,
            site_name: '',
            element: { siteView:false, siteEdit:false, view:false, edit:false, add:false, state: 'consulter' },
            link: { base: '', site: '', consult: '' },
            siteMenu: false,
            drawerStateless: true,
            rightDrawerWidth: '900px',
            bookmark: {},
            isAuthorized: usrGetters.getScopeRight
        }
    },
    watch: {
        $route () {
            // To refresh data if route is manualy changed or by bookmark
            if( this.$route.params.trs_id !== undefined && this.$route.params.trs_id > 0 && this.$route.params.trs_id !== this.trs_id ){
                this.trs_id = this.$route.params.trs_id 
                this.openRoute = false
                setTimeout( () => {
                    this.openRoute = true
                })
            }
        }
    },
    computed: {
        linkClose(){
            return this.link.base
        },
        sliderMode(){
            let params = this.link.consult.split('/')
            return params.length
        },
        bookmarkCount(){
            var total = 0
            var item
            for( item in this.bookmark ){
                if( item ){
                    total++
                }
            }
            return total
        }
    },
    beforeMount: function(){
        if( this.$route.params.trs_id !== undefined && this.$route.params.trs_id > 0 ){
            this.objTrackingSheet.trs_id = parseInt(this.$route.params.trs_id)
            this.trs_id                  = parseInt(this.$route.params.trs_id) 
        }
    },
    beforeUpdate(){
        this.link.base = '/#' + this.$route.matched[1].path
        this.bookmark  = cacheGetters.getBookmark()
    },
    mounted() {
        this.link.base = '/#' + this.$route.matched[1].path
    },
    methods:{
        openCardState(state){
            this.siteMenu = state
        },
        leftDrawerMaximize(){
            if( this.rightDrawerWidth == '900px' ){
                this.rightDrawerWidth = '100%'
                this.labels.windowSize= 'Réduire'
            } else {
                this.rightDrawerWidth = '900px'
                this.labels.windowSize= 'Agrandir'
            }
        },
        adaptSidebar(to){
            const pathList = to.fullPath.split('/') //this.$route.fullPath.split('/')
            if( to.params.trs_id !== undefined && to.params.trs_id > 0 ){
                this.objTrackingSheet.trs_id     = parseInt(to.params.trs_id)
                if( pathList[4] !== undefined ){
                    this.link.consult = "#/" + pathList[1] + '/' + pathList[2] + '/' + pathList[3]
                } else {
                    this.link.consult = "#/" + pathList[1]
                }
            } else {
                this.objTrackingSheet.trs_id = to.params.trs_id
            }
            if( to.params.trs_id !== undefined && to.params.trs_id == 'nouveau' ){
                this.element.edit  = true
                this.element.view  = true
                this.element.state      = 'nouveau'
            } else {
                this.element.edit  = false
                this.element.view  = true
                this.element.state      = 'apercu'
            }
            if( pathList[4] !== undefined && pathList[4] == 'modifier' ){
                this.element.edit  = true
                this.element.view  = false
                this.element.state      = 'modifier'
            }
            if( pathList[4] !== undefined && pathList[4] == 'consulter' ){
                this.element.edit  = false
                this.element.view  = true
                this.element.state      = 'consulter'
            }
            if( pathList[1] !== undefined && pathList[1] == 'sites' ){
                this.rightDrawerWidth = '1600px'
            } else {
                this.rightDrawerWidth = '900px'
            }
            //if( to.fullPath.split("/")[1] !== undefined && to.fullPath.split("/")[1] == 'carte' ){
            //    this.drawerStateless = true
            //} else {
            //    this.drawerStateless = false
            //}
            if( pathList[4] !== undefined ){
                this.link.consult = "#/" + pathList[1] + '/' + pathList[2] + '/' + pathList[3]
            } else {
                this.link.consult = "#/" + pathList[1]
            }
        },
        addToBookmark(){
            if( this.bookmark['b-' + this.objTrackingSheet.trs_id] !== undefined ){
                //delete this.bookmark['b-' + this.objTrackingSheet.trs_id]
                this.$delete(this.bookmark, 'b-' + this.objTrackingSheet.trs_id)
                cacheMutations.setBookmark(this.bookmark)
                cacheActions.storeCacheToStorage()
            } else {
                this.getTrackingSheetShow('town').then( () => {
                    let newBookmark = JSON.parse( JSON.stringify(this.objTrackingSheet))
                    this.$set(this.bookmark, 'b-' + this.objTrackingSheet.trs_id, newBookmark)
                    cacheMutations.setBookmark(this.bookmark)
                    cacheActions.storeCacheToStorage()
                })
            }
        }
    },
    components: {
        //'view-opencard': vOpencard
    },
    beforeRouteEnter(to, from, next){
        next(vm => {
            vm.adaptSidebar(to)
        })   
    },
    beforeRouteUpdate(to, from, next) {
        this.adaptSidebar(to)
        next()
    },
}

</script>
