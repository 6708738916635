<template>
<v-container fluid class="elevation-1 pa-0">
    <v-layout wrap style="background-color:white;" class="pa-2 elevation-1">
        <v-flex xs5 style="border-right:1px solid #E0E0E0;">
            <v-list dense>
                <template v-for="(itemLeft, indexLeft) in siteInfo['left']">
                <v-list-item :key="indexLeft">
                    <v-list-item-content>
                        <v-list-item-title>{{ itemLeft.text }}</v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action>
                        <v-list-item-action-text>
                            {{ (itemLeft.switch) ? itemLeft.value2 : itemLeft.value }}
                            <v-icon v-show="itemLeft.switch !== undefined" @click="itemLeft.switch = !itemLeft.switch">mdi-swap-vertical</v-icon>
                        </v-list-item-action-text>
                    </v-list-item-action>
                </v-list-item>
                </template>
            </v-list>
        </v-flex>
        <v-flex xs5 >
            <v-list dense>
                <template v-for="(itemRight, indexRight) in siteInfo['right']">
                <v-list-item :key="indexRight">
                    <v-list-item-content>
                        <v-list-item-title>{{ itemRight.text }}</v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action>
                        <v-list-item-action-text>{{ itemRight.value }}</v-list-item-action-text>
                    </v-list-item-action>
                </v-list-item>
                </template>
            </v-list>
        </v-flex>
        <v-flex xs2 >
            <v-list dense>
            <v-list-item><v-list-item-content>
                <!-- <v-btn small outlined light color="primary" :href="'/#/map/sheet/' + objTrackingSheet.trs_id ">Carte</v-btn> -->
            </v-list-item-content></v-list-item>
            </v-list>
        </v-flex>
    </v-layout>
    <!-- Block info with option -->
    <template v-for="(itemBlock, indexBlock) in blockObj">
        <v-subheader :key="'h-' + indexBlock" v-if="itemBlock.left.length > 0 || itemBlock.right.length > 0">
            <v-flex xs5>{{ itemBlock.left }}</v-flex>
            <v-flex xs7>{{ itemBlock.right }}</v-flex>
        </v-subheader>
        <v-layout wrap style="background-color:white;" class="mt-3 pa-2 elevation-1" :key="indexBlock">
            <v-flex xs5 style="border-right:1px solid #E0E0E0;">
                <v-list dense>
                    <template v-for="(itemLeft, indexLeft) in blockObj[indexBlock].data['left']">
                    <v-list-item :key="indexLeft">
                        <v-list-item-content><v-list-item-title>{{ itemLeft.text}}</v-list-item-title></v-list-item-content>
                        <v-list-item-action v-if="itemLeft.mod == 'chip'">
                            <v-chip v-if="itemLeft.value !== 0" color="primary" small label outlined>{{ itemLeft.value }} {{ (itemLeft.unit) || '' }}</v-chip>
                            <v-icon v-else light :title="itemLeft.title">mdi-information-outline</v-icon>
                        </v-list-item-action>
                        <v-list-item-action v-else><v-list-item-action-text>{{ itemLeft.value }}</v-list-item-action-text></v-list-item-action>
                    </v-list-item>
                    </template>
                </v-list>
            </v-flex>
            <v-flex xs5 >
                <v-list dense>
                    <template v-for="(itemRight, indexRight) in blockObj[indexBlock].data['right']">
                    <v-list-item :key="indexRight">
                        <v-list-item-content><v-list-item-title>{{ itemRight.text }}</v-list-item-title></v-list-item-content>
                        <v-list-item-action v-if="itemRight.mod == 'chip'">
                            <v-chip v-if="itemRight.value" color="primary" small label outlined>{{ itemRight.value }}</v-chip>
                            <v-icon v-else light :title="itemRight.title">mdi-information-outline</v-icon>
                        </v-list-item-action>
                        <v-list-item-action v-else><v-list-item-action-text>{{ itemRight.value }}</v-list-item-action-text></v-list-item-action>
                    </v-list-item>
                    </template>
                </v-list>
            </v-flex>
            <v-flex xs2 ></v-flex>
        </v-layout>
    </template>
    <v-subheader >Autre</v-subheader>
    <v-layout wrap style="background-color:white;" class="mt-0 pa-2 elevation-1">
        <v-btn grey rounded color="secondary" @click="goToDocument()">Voir les documents associés</v-btn>
    </v-layout>
    <v-subheader v-if="isAuthorized( 'trackingsheetedit', 'delete' )">Administration</v-subheader>
    <v-layout    v-if="isAuthorized( 'trackingsheetedit', 'delete' )" wrap style="background-color:white;" class="mt-0 pa-2 elevation-1">
        <v-flex xs6 class="pa-2">
            <v-btn block small outlined light :color="'orange'" @click="closeSheet()">Archiver la fiche</v-btn>
        </v-flex>
        <v-flex xs6 class="pa-2">
            <v-btn block small outlined light :color="'red'" @click="deleteSheet()">Supprimer la fiche</v-btn>
        </v-flex>
    </v-layout>

</v-container>
</template>

<script>
import { cacheGetters, cacheMutations } from "../store/cache.module"
import dateLocale       from '../services/dateLocale';
import { usrGetters }   from "../store/user.module"
import { trsObj }       from '../mixins/trs_obj.js'

export default {
    name: 'trs_slide_prev',
    props: ['toast', 'infTrackingSheet'], 
    mixins: [ trsObj ],
    data: function () {
        return {
            loading: { getSite: false },
            locDateFormat: dateLocale, // Format de date
            typeState: cacheGetters.getTypes(),
            activeTab: 0, showTab: true,
            sit_obj: {},
            blockObj: {
                info : { data: { 'left': [], 'right': [] }, 'left': '', 'right': '' },
                proc : { data: { 'left': [], 'right': [] }, 'left': 'Fiche', 'right': 'Info materiel' },
                fac  : { data: { 'left': [], 'right': [] }, 'left': 'Info. Facturation', 'right': '' },
            },
            mesureLabel: [ '', 'En demande', 'Validé', 'non validé' ],
            colorLabel: [ '#607D8B', '#607D8B', '#4CAF50', '#F44336' ],
            siteInfo: [],
            siteRecap: [],
            isAuthorized: usrGetters.getScopeRight
        }
    },
    computed: {

    },
    mounted:function (){
        if( this.$route.params.trs_id !== undefined && this.$route.params.trs_id > 0 ){
            this.objTrackingSheet.trs_id = this.$route.params.trs_id
        }
        //this.getData()
    },
    methods: {
        async getData(){
            await this.getTrackingSheetShow('town')
            await this.getTrackingStateIndex()

            this.updateSiteInfo()
            //var tst = this.objTrackingSheet._data['trackingstates']
            this.objTrackingSheet.trs_area_specification = Math.round( this.objTrackingSheet.trs_area_specification * 10 ) / 10
            this.blockObj.info.data['left'].push( { text: 'Surface', value: this.objTrackingSheet.trs_area_specification + ' ha' }) //tst['TST59'].tst_value
            this.blockObj.info.data['left'].push( { text: 'Début', value: this.locDateFormat.toLocale(this.objTrackingSheet.trs_date_start, { year: 'numeric', month: 'long', day: 'numeric' }) })
            this.blockObj.info.data['right'].push( { text: 'Type', value: this.typeState['SIT'][this.objTrackingSheet.trs_typ_id].typ_name })
            this.blockObj.info.data['right'].push( { text: 'Fin', value: this.locDateFormat.toLocale(this.objTrackingSheet.trs_date_end, { year: 'numeric', month: 'long', day: 'numeric' }) })
            this.blockObj.info.data['right'].push( { text: 'Distance à la ligne', value: this.objTrackingSheet.trs_rail_distance })

            this.updateSpecification()
        },
        updateSiteInfo(){
            var tabInfo = { 'left': [], 'right': [] }
            tabInfo['left'].push( { text: 'Identifiant', value: this.objTrackingSheet.trs_mc2_code, switch: true, text2: 'Coddt', value2: this.objTrackingSheet.trs_coddt } )
            tabInfo['left'].push( { text: 'Commune', value: this.objTrackingSheet._info.town.tow_name } )
            tabInfo['right'].push( { text: 'Nom', value: this.objTrackingSheet.trs_name })
            tabInfo['right'].push( { text: 'Dernière modification', value: this.locDateFormat.toLocale(this.objTrackingSheet.trs_updated_at, { year: 'numeric', month: 'long', day: 'numeric' }) })
            this.siteInfo = tabInfo
        },
        updateSpecification(){
            this.$http.get( 'specification/?per_page=false&extended=withcoderef&spf_trs_id=' +  this.objTrackingSheet.trs_id).then( (response) => {
                response.data.data.forEach( (item) => {
                    item.title    = item.spf_custom_fields['spf_precision'] || 'Aucune information'
                    item.spf_area = Math.round(item.spf_area * 10) / 10
                    this.blockObj.proc.data['left'].push( { text: item.cdr_code, value: item.spf_area, title:item.title, unit:'h', mod:'chip' })
                })
            })
        },
        goToDocument(){
            cacheMutations.setSearchBarItem('documents', this.objTrackingSheet.trs_coddt)
            this.$router.push({ path: '/documents' })
        },
        closeSheet(){
            this.$root.$confirm('Cloturer', 'Confirmer la fermeture de cette fiche', { color: 'red', width: 500 }).then((confirm) => {
                if( confirm ){
                    this.objTrackingSheet.trs_sta_id = "GEN04"
                    this.putTrackingSheetStore().then( () => {
                        this.toast({ color: 'success', top:false, bottom:true, right:false, left:true, text:'Fiche clôturée !' })
                        this.$router.push({ path: '/trackingsheet' })
                    })
                }
            })
        },
        deleteSheet(){
            this.$root.$confirm('Supprimer', 'Confirmer la suppression de cette fiche', { color: 'red', width: 500 }).then((confirm) => {
                if( confirm ){
                    this.objTrackingSheet.trs_sta_id = "GEN05"
                    this.putTrackingSheetStore().then( () => {
                        this.toast({ color: 'success', top:false, bottom:true, right:false, left:true, text:'Fiche supprimée !' })
                        this.$router.push({ path: '/trackingsheet' })
                    })
                }
            })
        },
        getCacheType: cacheGetters.getTypes,
        getCacheState: cacheGetters.getStates
    },
    components: {

    }
}
</script>