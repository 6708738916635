var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"pa-1",attrs:{"fluid":""}},[_c('v-toolbar',{staticClass:"elevation-0",attrs:{"height":"48px"}},[_c('v-toolbar-title',[_vm._v("Liste des fichiers")]),_c('v-spacer'),(_vm.enableAdd && _vm.isAuthorized( 'document', 'edit' ))?_c('v-btn',{attrs:{"outlined":"","rounded":"","small":"","color":(_vm.routeState.view) ? 'grey' : 'green',"disabled":!_vm.routeState.edit},on:{"click":function($event){return _vm.openDocument(0)}}},[_vm._v(" Ajouter"),_c('v-icon',[_vm._v("mdi-playlist-edit")])],1):_vm._e()],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"item-key":"sta_id","items-per-page-text":"Lignes par page","items-per-page-options":_vm.pagination.rowsPerItem,"loading":_vm.loading.getDocument},scopedSlots:_vm._u([{key:"item.doc_created_at",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"label":"","outlined":""}},[_vm._v(_vm._s(_vm.locDateFormat.formatLocale( item.doc_created_at, 'fr' )))])]}},{key:"item.doc_name",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"d-inline-block text-truncate",staticStyle:{"max-width":"400px"}},[_vm._v(" "+_vm._s(item.doc_name)+" ")])]}},{key:"item.doc_id",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"label":"","small":"","outlined":"","title":"Télécharger"},on:{"click":function($event){return _vm.downloadDocument(item.doc_id)}}},[_c('v-icon',{attrs:{"left":"","color":"grey"}},[_vm._v("mdi-file-download")])],1),(_vm.isAuthorized( 'document', 'edit' ))?_c('v-chip',{attrs:{"label":"","small":"","outlined":"","title":"Editer"},on:{"click":function($event){return _vm.editDocument(item.doc_id)}}},[_c('v-icon',{attrs:{"left":"","color":"orange"}},[_vm._v("mdi-pencil")])],1):_vm._e(),(_vm.isAuthorized( 'document', 'delete' ))?_c('v-chip',{attrs:{"label":"","small":"","outlined":"","title":"Supprimer"},on:{"click":function($event){return _vm.deleteDocument(item.doc_id)}}},[_c('v-icon',{attrs:{"left":"","color":"red"}},[_vm._v("mdi-delete-forever")])],1):_vm._e()]}},{key:"pageText",fn:function(ref){
var pageStart = ref.pageStart;
var pageStop = ref.pageStop;
return [_vm._v(" De "+_vm._s(pageStart)+" à "+_vm._s(pageStop)+" ")]}}])},[_c('template',{slot:"no-data"},[_c('v-alert',{attrs:{"value":( !_vm.loading.getDocument ),"color":"error","icon":"mdi-alert"}},[_vm._v(" Aucune fichier, ajoutez un nouveau fichier ")])],1)],2),_c('v-dialog',{attrs:{"max-width":"900"},model:{value:(_vm.modalLayer),callback:function ($$v) {_vm.modalLayer=$$v},expression:"modalLayer"}}),_c('v-dialog',{attrs:{"max-width":"900"},model:{value:(_vm.modalDocument),callback:function ($$v) {_vm.modalDocument=$$v},expression:"modalDocument"}},[_c('v-card',{attrs:{"color":"grey lighten-4","min-width":"350px","flat":""}},[_c('v-toolbar',{staticClass:"elevation-1",attrs:{"color":"primary","dark":"","height":"50px"}},[_c('v-btn',{attrs:{"icon":""}},[_c('v-icon',[_vm._v("mdi-chevron-down")])],1),_c('v-toolbar-title',[_vm._v("Document")]),_c('v-spacer'),_c('v-btn',{attrs:{"icon":"","color":""},nativeOn:{"click":function($event){return _vm.switchModal('modalDocument', 0)}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-card-text',{staticClass:"mt-2"},[(_vm.modalDocument)?_c('edit-document',{staticClass:"elevation-2",attrs:{"toast":_vm.toast,"switchModal":_vm.switchModal,"xxxId":_vm.doc_xxx_id,"docId":_vm.docInfo.doc_id,"docTable":_vm.table_id,"docType":'DOC'}}):_vm._e()],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }