<template>
<div>
    <v-autocomplete ref="autoTeam" v-model="listSelected" :items="listItem" 
        @input="autoImput" :search-input.sync="search" @change="saveTeam = true"
        :item-text="textToshow" :item-value="fields.index" :label="title" style="z-index:2000;"
        :multiple="multiSelect" :prepend-icon="preprendIcon" chips small-chips clearable hide-selected 
        :auto-select-first= "fields.autoselectfirst" :disabled="isDisabled">
        <template v-slot:no-data>
            <span class="ma-2">Effectuer une recherche</span>
        </template>
    </v-autocomplete>
</div>
</template>

<script>

export default {
    name: 'autocomplete_ressource',
    props: ['ressource', 'listParent', 'fields', 'title', 'listObject', 'disabled'], 
    data: function () {
        return {
            loading     : { getVersion: false, getRessource: false },
            search      : null,
            listSelected: null,
            listItem    : [],
            saveRessource : false,
            modalRessource: false,
            multiSelect   : true,
            preprendIcon  : 'mdi-account',
            params        : '',
            isDisabled    : this.disabled || false
        }
    },
    watch: {
        search (val) {
            if( this.loading.getRessource ){
                clearTimeout(this.loading.getRessource)
            }
            if( this.ressource && val && val.length > 1 ){
                var searchTab = this.fields.search.split(',')
                var search = ''
                searchTab.forEach( (elem) => {
                    search += '&' + elem.trim() + '=%' + val + '%'
                })
                search = '&' + searchTab[0].trim() + '=%' + val + '%' //Todoo multi -> delete this line
                this.loading.getRessource = setTimeout( () => {
                    this.$http.get( '/' + this.ressource + '/?per_page=25' + this.params + encodeURI(search) ).then( (response) => {
                        this.listItem = this.listParent.concat(response.data.data)
                    })
                }, 800)
            } else {
                return false
            }
        },
        listObject() {
            this.listSelected = this.listObject
        }
    },
    beforeMount: function(){
        this.fields.autoselectfirst = this.fields.autoselectfirst || false
        this.fields.params          = this.fields.params || ''
        this.params                 = ( this.fields.params.length > 0 ) ? '&' + this.fields.params : '';
    },
    mounted:function (){
        this.multiSelect  = (this.fields.multiSelect == undefined) ? true : this.fields.multiSelect
        this.preprendIcon = (this.fields.preprendIcon == undefined) ? 'mdi-account' : this.fields.preprendIcon
        this.listItem     = this.listParent
        if( this.multiSelect && this.listObject !== undefined && this.listObject.length > 0 ){
            this.listSelected = this.listObject
        } else if( !this.multiSelect && this.listObject !== undefined ){
            this.listSelected = this.listObject
        }
    },
    methods: {
        autoImput(){
            var listObject = []
            this.listItem.forEach(element => {
                if( this.listSelected!== undefined && this.listSelected.isArray ){
                    if( this.listSelected.indexOf( element[ this.fields.index ] ) > -1 ){
                        listObject.push( element ) //this.listParent.push( element )
                    }
                } else {
                    listObject.push( element )
                }
            })
            this.$emit('update:listParent', listObject)
            this.$emit('update:listObject', this.listSelected)
            this.$emit('change', this.listSelected)
        },
        switchModal(name, mode){
            name    = name || 'modalressource'
            mode    = mode || 0
            //refresh = refresh || false
            this[name] = mode
        },
        textToshow( item ){
            let text   = ''
            let fields = this.fields.text.split(',')
            fields.forEach(element => {
                text += (text.length > 0) ? ' - ' + item[element] : item[element]
            })
            return text
        }
    },
    components: {

    }
}
</script>