<template>
<v-container fluid class="pa-0 ma-0">
<v-card style="z-index:1000;" light>
<v-app-bar flat color="white">
    <v-toolbar-title><v-icon class="mr-3 " color="success" v-text="'mdi-cogs'" ></v-icon> Filtre général</v-toolbar-title>
    <v-spacer></v-spacer>
    <v-btn text class="pl-0" @click="clearFilter"><v-icon :color="(filterCount) ? 'red' : 'grey'" title="Réinitialiser mon filtre">mdi-refresh</v-icon></v-btn>
    <v-btn text :class="(filterCount) ? 'red--text' : ''" @click="saveFilter" title="Enregistrer le filtre">
        <v-icon :color="(filterCount) ? 'green' : 'grey'">mdi-content-save</v-icon>
    </v-btn>
</v-app-bar>
<v-divider></v-divider>
<v-layout row wrap class="mt-3 pl-5 pr-5 pb-5">
    <v-flex xs6 class="pa-2">
        <auto-multi :title="'Matricule'" ressource="matricule" :listObject.sync="filter.tag_id"
            :listParent.sync="filterSource.dep" :fields="{ index: 'tag_id', text:'dep_name', search:'dep_name,dep_code', preprendIcon:'mdi-view-comfy' }"
            class="ml-2 mr-2">
        </auto-multi>
        <auto-multi :title="'Marque'" ressource="matricule" :listObject.sync="filter.tag_id"
            :listParent.sync="filterSource.dep" :fields="{ index: 'tag_id', text:'dep_name', search:'dep_name,dep_code', preprendIcon:'mdi-table-merge-cells' }"
            class="ml-2 mr-2">
        </auto-multi>
    </v-flex>
    <v-flex xs6 class="pa-2">
        <auto-multi :title="'Modèle'" ressource="matricule" :listObject.sync="filter.tag_id"
            :listParent.sync="filterSource.dep" :fields="{ index: 'tag_id', text:'dep_name', search:'dep_name,dep_code', preprendIcon:'mdi-tag' }"
            class="ml-2 mr-2">
        </auto-multi>
        <auto-multi :title="'Version'" ressource="matricule" :listObject.sync="filter.tag_id"
            :listParent.sync="filterSource.dep" :fields="{ index: 'tag_id', text:'dep_name', search:'dep_name,dep_code', preprendIcon:'mdi-barcode' }"
            class="ml-2 mr-2">
        </auto-multi>
    </v-flex>
    <v-flex xs12>
        <v-btn rounded color="success" @click="closeWindow()">Appliquer</v-btn>
    </v-flex>
</v-layout>
</v-card>
</v-container>
</template>

<script>
import { cacheData, cacheGetters, cacheMutations, cacheActions } from "../store/cache.module"
import autoCompMulti    from './Autocomplete_multi.vue'
//import { store, mutations } from "../services/store";

export default {
    name: 'view-filter',
    props: ['filterDiff', 'filterCount', 'filterView'],
    data: function () {
        return {
            saveProgress: false,
            loading: { getItem: true },
            typSit: [],
            menu: false,
            filter: {
                tag_id:     [],
            },
            filterSource:{
                tag: [],
            },
            typSource: [],

            filterTab : [],
            sitTab : [],
            storeFilter: {}
        }
    },
    watch: {
        filterView () {
            this.updateFilter()
        }
    },
    beforeMount: function(){
        this.initFilter()
    },
    mounted:function (){
    },
    methods: {
        getCacheType: cacheGetters.getTypes,
        initFilter(){
            for(var prop in this.filter){
                if( cacheData.filter[prop] === undefined ){
                    cacheData.filter[prop] = this.filter[prop]
                }
            }
            this.filter      = cacheGetters.getFilter()
            //Object.assign( this.filter, cacheGetters.getFilter())
            this.storeFilter = JSON.parse( JSON.stringify( this.filter ) ) // todoo init if empty
            this.filterSource.spf = cacheGetters.getTypes( 'GEN' )
        },
        updateFilter(){
            var total = 0
            var diff  = []
            for( let prop in this.filter ){
                if( Array.isArray( this.filter[prop] ) ){
                    diff = this.storeFilter[prop].filter(x => this.filter[prop].indexOf(x) < 0 )
                    total += diff.length
                    diff = this.filter[prop].filter(x => this.storeFilter[prop].indexOf(x) < 0 )
                    total += diff.length
                }
            }
            this.$emit('update:filterCount', total)
        },
        removeItem (item) {
            let index = this.filter.trs_typ_id.indexOf(item.sit_id)
            if (index >= 0) { 
                this.filter.trs_typ_id.splice(index, 1)
            }
        },
        saveFilter(){
            cacheMutations.setFilter( this.filter )
            cacheActions.storeCacheToStorage()
            this.storeFilter = JSON.parse( JSON.stringify( this.filter ) )
        },
        clearFilter(){
            var filter      = cacheGetters.getFiltrerFromStorage()
            for(var prop in filter){
                if( Object.prototype.toString.call( this.filter[prop] ) === '[object Array]' ) {
                    this.filter[prop] = filter[prop].slice( this.filter[prop] )
                } else if( this.filter[prop] !== undefined ){
                    this.filter[prop] = filter[prop]
                }
            }
            this.updateFilter()
        },
        closeWindow(){
            this.$emit('update:filterView', false)
        },
        removeChip (item) {
            const index = this.filter.trs_typ_id.indexOf(item.typ_id)
            if (index >= 0) this.filter.trs_typ_id.splice(index, 1)
        },
    },
    components: {
        'auto-multi': autoCompMulti
    }
}
</script>