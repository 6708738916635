<template>
<v-card>
    <v-card-text>
    <v-layout row wrap>
        <v-flex xs6 class="pa-2">
            <v-menu ref="datestart" v-model="dateMenu.date_start"
                :return-value.sync="objEvent.eve_date_start"
                :close-on-content-click="false" :nudge-right="40" transition="scale-transition" 
                offset-y min-width="290px" style="z-index:1500">
                <template v-slot:activator="{ on, attrs }">
                    <v-text-field 
                        v-bind="attrs" :value="locDateFormat.formatLocale( objEvent.eve_date_start, 'fr' )"
                        label="Date début" prepend-icon="mdi-calendar-today" readonly v-on="on" >
                    </v-text-field>
                </template>
                <v-date-picker locale="fr-fr" no-title scrollable v-model="objEvent.eve_date_start">
                    <v-spacer></v-spacer>
                    <v-btn color="primary" @click="dateMenu.date_start = false">Annuler</v-btn>
                    <v-btn color="primary" @click="$refs.datestart.save( objEvent.eve_date_start )">OK</v-btn>
                </v-date-picker>
            </v-menu>
        </v-flex>
        <v-flex xs6 class="pa-2">
            <v-menu ref="dateend" v-model="dateMenu.date_end"
                :return-value.sync="objEvent.eve_date_end"
                :close-on-content-click="false" :nudge-right="40" transition="scale-transition" 
                offset-y min-width="290px" style="z-index:1500">
                <template v-slot:activator="{ on, attrs }">
                    <v-text-field 
                        v-bind="attrs" :value="locDateFormat.formatLocale( objEvent.eve_date_end, 'fr' )"
                        label="Date fin" prepend-icon="mdi-calendar-today" readonly v-on="on" >
                    </v-text-field>
                </template>
                <v-date-picker locale="fr-fr" no-title scrollable v-model="objEvent.eve_date_end">
                    <v-spacer></v-spacer>
                    <v-btn color="primary" @click="dateMenu.date_end = false">Annuler</v-btn>
                    <v-btn color="primary" @click="$refs.dateend.save( objEvent.eve_date_end )">OK</v-btn>
                </v-date-picker>
            </v-menu>
        </v-flex>
        <v-flex xs6 class="pa-2">
            <v-text-field label="Code" placeholder="E001" v-model="objEvent.eve_code"></v-text-field>
        </v-flex>
        <v-flex xs6 class="pa-2">
            <v-select :items="types" item-text="typ_name" item-value="typ_id" v-model="objEvent.eve_typ_id" label="Statut" single-line >   
            </v-select>
        </v-flex>
        <v-flex xs6 class="pa-2">
            <v-text-field label="Valeur" v-model="objEvent.eve_value"></v-text-field>
        </v-flex>
        <v-flex xs3 class="pa-2">
            <v-text-field label="Cout" v-model="objEvent.eve_cost_value"></v-text-field>
        </v-flex>
        <v-flex xs3 class="pa-2">
            <v-checkbox :true-value="1" :false-value="0" v-model="objEvent.eve_delegation" label="Sous traité"></v-checkbox>
        </v-flex>
        <v-flex xs12 class="pa-2">
            <v-text-field label="Description" v-model="objEvent.eve_description"></v-text-field>
        </v-flex>
        <v-flex xs12 class="pa-2">
            <v-subheader class="pl-0">Résolution / avancement</v-subheader>
            <v-slider class="mx-3"
            v-model="objEvent.eve_resolve_percent" :step="10"
            thumb-label="always"
            ></v-slider>
        </v-flex>
    </v-layout>
    </v-card-text>
    <v-divider light></v-divider>
    <v-card-actions>
        <v-menu v-if="objEvent.eve_id > 0" v-model="optionMenu" offset-y transition="slide-x-transition" bottom right >
            <template v-slot:activator="{ on }">
            <v-btn v-on="on" outlined class="ml-0 pa-0" style="min-width: 5px;" color="grey"><v-icon>mdi-dots-vertical</v-icon></v-btn>
            </template>
            <v-btn class="ma-0" color="error" @click.native="deleteEvent()" :disabled="saveProgress">
                <v-icon left>mdi-trash-can</v-icon>Supprimer
            </v-btn>
        </v-menu>
        <v-spacer></v-spacer>
        <v-btn rounded  
            color="success" 
            :loading="saveProgress" @click.native="saveEvent()" :disabled="saveProgress">
            Enregistrer
        </v-btn>
        <v-btn rounded outlined color="grey" @click="switchModal('modalEvent', 0)">
            Fermer
        </v-btn>
    </v-card-actions>
</v-card>
</template>

<script>
import dateLocale from '../services/dateLocale';
import { eveObj } from '../mixins/eve_obj.js'
import { cacheGetters } from "../store/cache.module"

export default {
    name: 'eventedit',
    props: ['toast', 'xxxId', 'eveId', 'eveTable', 'eveType', 'switchModal'],
    mixins: [ eveObj ],
    data: function () {
        return {
            dateMenu: { date_start:false, date_end:false },
            saveProgress: false,         // Enregistrement en cours...
            locDateFormat: dateLocale, // Format de date
            states : [],
            types : [],
            dateEve: { start: false, end: false },
            optionMenu: false,
            eventType: {
                'trs' : [ 'WRK' ],
                'spf' : [ 'MES' ],
                'inv' : [ 'INE' ],
            }
        }
    },
    //beforeMount: function(){ },
    mounted:function (){
        if ( this.eveType ){
            this.types = this.types.concat( cacheGetters.getTypes(this.eveType) )
        } else if( this.eventType[this.eveTable] !== undefined ){
            this.eventType[this.eveTable].forEach(element => {
                this.types = this.types.concat( cacheGetters.getTypes(element) )
            });
        } else {
            this.types = this.types.concat( cacheGetters.getTypes('ACT') )
        }
        if( this.eveId !== undefined && this.eveId > 0 ){
            this.objEvent.eve_id = this.eveId
            this.getEventShow()
        } else {
            this.cleanObjEvent()
            this.objEvent.eve_id         = 0
            this.objEvent.eve_typ_id     = this.types[0].typ_id
            this.objEvent.eve_table_id   = this.eveTable
            this.objEvent.eve_xxx_id     = this.xxxId
            this.objEvent.eve_date_start = this.locDateFormat.getToday('en')
        }
    },
    methods: {
        saveEvent(){
            if( this.objEvent.eve_id !== undefined && this.objEvent.eve_id > 0 ){
                this.putEventUpdate().then( () => {
                    this.switchModal('modalEvent', 0, true)
                })
            } else {
                this.postEventStore().then( () => {
                    this.switchModal('modalEvent', 0, true)
                })
            }
        },
        deleteEvent(){
            this.deleteObjEvent().then( () => {
                this.switchModal('modalEvent', 0, true)
            })
        }
    },
    components: { }
}
</script>