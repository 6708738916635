<template>
<v-container fluid class="elevation-1 pa-0 pb-2">
    <v-layout wrap style="background-color:white;" class="pa-2 elevation-1">
        <v-flex xs5 style="border-right:1px solid #E0E0E0;">
            <v-list dense>
                <template v-for="(itemLeft, indexLeft) in blockObj.info.data['left']">
                <v-list-item :key="indexLeft">
                    <v-list-item-content>
                        <v-list-item-title>{{ itemLeft.text }}</v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action>
                        <v-list-item-action-text>
                            {{ (itemLeft.switch) ? itemLeft.value2 : itemLeft.value }}
                            <v-icon v-show="itemLeft.switch !== undefined" @click="itemLeft.switch = !itemLeft.switch">mdi-swap-vertical</v-icon>
                        </v-list-item-action-text>
                    </v-list-item-action>
                </v-list-item>
                </template>
            </v-list>
        </v-flex>
        <v-flex xs5 >
            <v-list dense>
                <template v-for="(itemRight, indexRight) in blockObj.info.data['right']">
                <v-list-item :key="indexRight">
                    <v-list-item-content>
                        <v-list-item-title>{{ itemRight.text }}</v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action>
                        <v-list-item-action-text>{{ itemRight.value }}</v-list-item-action-text>
                    </v-list-item-action>
                </v-list-item>
                </template>
            </v-list>
        </v-flex>
        <v-flex xs2 >
            <v-list dense>
            <v-list-item v-if="(!routeState.edit)"><v-list-item-content>
                <v-btn small outlined light color="primary" :href="'/#/map/sheet/' + objTrackingSheet.trs_id + '/consulter' ">Carte</v-btn>
            </v-list-item-content></v-list-item>
            <v-list-item v-if="(routeState.edit)"><v-list-item-content>
                <v-btn small outlined light :color="'green'" @click="saveSite()">
                    Enregistrer
                </v-btn>
            </v-list-item-content></v-list-item>
            <!-- <v-list-item><v-list-item-content>
                <v-btn small outlined light :color="'grey'" v-show="(routeState.edit)">
                    Rétablir
                </v-btn>
            </v-list-item-content></v-list-item> -->
            </v-list>
        </v-flex>
    </v-layout>

    <!-- Associative Tab -->
    <v-card fluid class="ma-2 pa-0 elevation-1">
        <v-divider></v-divider>
        <v-tabs v-model="activeTab" grow light show-arrows="mobile">
            <v-tabs-slider color="green"></v-tabs-slider>
            <v-tab><v-icon class="mr-1">mdi-file-document-box-check</v-icon><span class="hidden-sm-and-down">Fiche</span></v-tab>
            <v-tab><v-icon class="mr-1">mdi-file-document-box-check</v-icon><span class="hidden-sm-and-down">Travaux</span></v-tab>
            <v-tab><v-icon class="mr-1">mdi-paperclip</v-icon><span class="hidden-sm-and-down">Documents</span></v-tab>
            <v-tab v-show="isAuthorized( 'trackingsheetedit', 'edit' )"><v-icon class="mr-1">mdi-calendar-outline</v-icon><span class="hidden-sm-and-down">Evenements</span></v-tab>
<!--            <v-tab><v-icon class="mr-1">mdi-comment-check-outline</v-icon><span class="hidden-sm-and-down">Avis</span></v-tab>
            <v-tab v-show="objTrackingSheetInfo.typeContract == 'COV'"><v-icon class="mr-1">mdi-script-text-outline</v-icon><span class="hidden-sm-and-down">Convention</span></v-tab>
            <v-tab><v-icon class="mr-1">mdi-information-variant</v-icon><span class="hidden-sm-and-down">Données foncières</span></v-tab>
            <v-tab v-show="isAuthorized( 'trackingsheetedit', 'edit' )"><v-icon class="mr-1">mdi-receipt</v-icon><span class="hidden-sm-and-down">Facturation</span></v-tab>
-->
        </v-tabs>
        <v-divider></v-divider>
        <procedure-tab v-if="activeTab === 0 && showTab == true" :toast="toast" :objTrackingSheet.sync="objTrackingSheet"  :table_id="'trs'" :routeState="routeState" ></procedure-tab>
        <work-tab      v-if="activeTab === 1 && showTab == true" :toast="toast" :objTrackingSheet.sync="objTrackingSheet"  :table_id="'trs'" :routeState="routeState" ></work-tab>
        <tab-doc       v-if="activeTab === 2 && showTab == true" :toast="toast" :doc_xxx_id="objTrackingSheet.trs_id"      :table_id="'trs'" :routeState="routeState" ></tab-doc>
        <event-tab     v-if="activeTab === 3 && showTab == true && isAuthorized( 'trackingsheetedit', 'edit' )" :toast="toast" :eve_xxx_id.sync="objTrackingSheet.trs_id" :table_id="'trs'" :routeState="routeState" :title="'Evenements'"></event-tab>
<!--        <view-tab      v-if="activeTab === 3 && showTab == true" :toast="toast" :objTrackingSheet.sync="objTrackingSheet"  :table_id="'trs'" :routeState="routeState" ></view-tab>
        <conv-tab      v-if="activeTab === 5 && showTab == true" :toast="toast" :objTrackingSheet.sync="objTrackingSheet"  :table_id="'trs'" :routeState="routeState" ></conv-tab>
        <landed-tab    v-if="activeTab === 6 && showTab == true" :toast="toast" :objTrackingSheet.sync="objTrackingSheet" :objTrackingSheetInfo.sync="objTrackingSheetInfo" :routeState="routeState" :table_id="'trs'"></landed-tab>
        <bil-tab       v-if="activeTab === 7 && showTab == true && isAuthorized( 'trackingsheetedit', 'edit' )" :toast="toast" :objTrackingSheet.sync="objTrackingSheet" :table_id="'trs'" :routeState="routeState"></bil-tab>
-->
    </v-card>
</v-container>
</template>

<script>
import dateLocale   from '../services/dateLocale';
import sheetTab     from '../components/Tab_sheet.vue'
import workTab     from '../components/Tab_work.vue'
import documentTab  from '../components/Tab_document.vue'
import eventTab     from '../components/Tab_event.vue'
//import viewTab      from '../components/Tab_view.vue'
import { usrGetters }   from "../store/user.module"
import { trsObj }       from '../mixins/trs_obj.js'

export default {
    name: 'view_trackingsheet',
    props: ['toast', 'link', 'objSite', 'routeState'],
    mixins: [ trsObj ],
    data: function () {
        return {
            trsLoaded: false,
            loading: { getSite: false },
            locDateFormat: dateLocale, // Format de date
            activeTab: 0, showTab: false,
            blockObj: {
                info: { data: { 'left': [], 'right': [] }, 'left': '', 'right': '' },
            },
            card_obj: {},
            isAuthorized: usrGetters.getScopeRight
        }
    },
    mounted: function (){
        if( this.$route.params.trs_id !== undefined && this.$route.params.trs_id > 0 ){
            this.objTrackingSheet.trs_id = this.$route.params.trs_id
        }
        this.blockObj.info.data['left'].push( { text: 'Identifiant', value: 'TR6548522', switch: true, text2: 'Coddt', value2: 'RT-879-AA' } )
        this.blockObj.info.data['right'].push( { text: 'Type', value: 'Tondeuse' } )
        //this.getSite()
        this.showTab = true
    },
    computed: {

    },
    methods: {
        getSite(){
            this.getTrackingSheetShow('town,watermass,address').then( () => {
                this.getTrackingStateIndex().then( () => {
                    this.blockObj.info.data['left'].push( { text: 'Identifiant', value: this.objTrackingSheet.trs_mc2_code, switch: true, text2: 'Coddt', value2: this.objTrackingSheet.trs_coddt } )
                    this.blockObj.info.data['left'].push( { text: 'Nom / ville', value: this.objTrackingSheet._info.town.tow_name } )
                    this.getTrackingContactIndex().then( () =>{
                        this.showTab = true
                        this.cloneTrackingSheetObj()
                    })
                    this.blockObj.info.data['right'].push( 
                        { text: 'Modification', value: this.locDateFormat.toLocale(this.objTrackingSheet.trs_updated_at, { year: 'numeric', month: 'long', day: 'numeric' }) }
                    )
                })
            })
        },
        saveSite(){
            this.saveTrackingSheet()
        }
    },
    components: {
        'procedure-tab': sheetTab,
        'tab-doc' : documentTab,
        'event-tab': eventTab,
        'work-tab': workTab,
    }
}
</script>