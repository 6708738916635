<template>
<v-container fluid>
<v-row>
    <v-col cols="12" md="12">
        <v-card :class="uiParams.cardElevation" tile flat>
            <v-toolbar flat color="#f6f8f8" prominent height="50px">
                <v-toolbar-title><v-icon>mdi-chart-bar</v-icon> Échéances fin de contrats</v-toolbar-title>
                <v-spacer></v-spacer>
                <!-- <v-btn icon @click="fillData()"><v-icon>mdi-refresh</v-icon></v-btn> -->
            </v-toolbar>
            <v-divider></v-divider>
            <v-card-text>
                <v-data-table v-bind:headers="contractheaders" :items="contractitems" item-key="sta_id"
                    items-per-page-text="Lignes par page" :items-per-page-options="pagination.rowsPerItem" hide-default-footer>
                </v-data-table>
            </v-card-text>
        </v-card>
    </v-col>
    <v-col cols="12" md="12">
        <v-card :class="uiParams.cardElevation" tile flat v-if="isAuthorized( 'dashboard', 'lastsheet' )">
        <v-toolbar flat color="#f6f8f8" prominent height="50px">
            <v-toolbar-title>
                <v-icon>mdi-format-list-checks</v-icon> Échéances techniques
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <!-- <v-btn icon><v-icon>mdi-restore</v-icon></v-btn> -->
        </v-toolbar>
        <v-divider></v-divider>
            <v-card-text>
                <v-data-table v-bind:headers="techheaders" :items="techitems" item-key="sta_id" hide-default-footer>
                </v-data-table>
            </v-card-text>
        <v-card-actions style="cursor:pointer;" @click="goRoute('preventive')">
        </v-card-actions>
        </v-card>
    </v-col>
</v-row>

<v-layout row justify-center>
    <v-dialog v-model="dialogsite" hide-overlay persistent max-width="600px">
        <v-card color="grey lighten-4" min-width="350px" flat >
        <v-toolbar color="primary" dark>
            <v-btn icon><v-icon>mdi-chevron-down</v-icon></v-btn>
            <v-toolbar-title>Sites: </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon color="" @click.native="dialogsite = false;"><v-icon>mdi-close</v-icon></v-btn>
        </v-toolbar>       
        <v-card-text>
            <v-textarea solo label="Informations utiles" hint="Ajouter modifier des informations"></v-textarea>
        </v-card-text>
        </v-card>
    </v-dialog>
</v-layout>
</v-container>
</template>

<script>
import dateLocale from '../services/dateLocale';
//import VueApexCharts from 'vue-apexcharts'
import { cacheGetters } from "../store/cache.module"
import { trsObj }  from '../mixins/trs_obj.js'
import apexLocale  from '../plugins/apexchart.js'
import { usrGetters }   from "../store/user.module"

export default {
    name: 'dashboard',
    props: ['toast', 'filterView'],
    mixins: [ trsObj ],
    data () {
        return {
            saveProgress: false,         // Enregistrement en cours...
            search: '',
            pagination: { totalItems: 0, rowsPerPage: 10, rowsPerItem: [15,25,35,{text:'Tout',value:-1}] },
            contractheaders: [
            { text: 'Échéances fin de contrats', align: 'left', value: 'con_end'},
            { text: 'Dépassées',                 align: 'left', value: 'con_out'},
            { text: 'à 30 J',                    align: 'left', value: 'con_30j'},
            { text: 'à 60 J',                    align: 'left', value: 'con_60j'},
            ],
            contractitems: [
                {con_60j: '1', con_30j: '1', con_out: '5', con_end: "Calendaires"},
                {con_60j: '0', con_30j: '0', con_out: '', con_end: "Estimées selon moyenne d'activité depuis l'origine"},
            ],
            techheaders: [
            { text: 'Échéances techniques', align: 'left', value: 'tech_end'},
            { text: 'Dépassées',            align: 'left', value: 'tech_out'},
            { text: 'à 30 J',               align: 'left', value: 'tech_30j'},
            { text: 'à 60 J',               align: 'left', value: 'tech_60j'},
            ],
            techitems: [
                {tech_60j: '1', tech_30j: '1',   tech_out: '', tech_end: "Air comprimé"},
                {tech_60j: '0', tech_30j: '0',   tech_out: '',  tech_end: "Chronotachygraphe"},
                {tech_60j: '1', tech_30j: '1',   tech_out: '', tech_end: "Contrôle électrique"},
                {tech_60j: '1', tech_30j: '1',   tech_out: '', tech_end: "Contrôle VGP"},
                {tech_60j: '0', tech_30j: '0',   tech_out: '',  tech_end: "Contrôle Pollution"},
                {tech_60j: '1', tech_30j: '1',   tech_out: '2', tech_end: "Contrôle Pollution LLD"},
                {tech_60j: '1', tech_30j: '1',   tech_out: '3', tech_end: "Contrôle Technique"},
                {tech_60j: '0', tech_30j: '0',   tech_out: '',  tech_end: "Contrôle Technique LLD"},
                {tech_60j: '0', tech_30j: '1',   tech_out: '', tech_end: "Contrôle portail automatique"},
                {tech_60j: '0', tech_30j: '1',   tech_out: '', tech_end: "Extincteur"},
                {tech_60j: '0', tech_30j: '1',   tech_out: '', tech_end: "Limiteur"},
                {tech_60j: '0', tech_30j: '6',   tech_out: '', tech_end: "Liste A"},
                {tech_60j: '36', tech_30j: '15', tech_out: '', tech_end: "Liste B"},
                {tech_60j: '1', tech_30j: '1',   tech_out: '', tech_end: "Liste C"},
                {tech_60j: '1', tech_30j: '1',   tech_out: '', tech_end: "Liste D"},
                {tech_60j: '1', tech_30j: '1',   tech_out: '', tech_end: "Liste E"},
                {tech_60j: '1', tech_30j: '1',   tech_out: '', tech_end: "Mines"},
            ],
            caracLeft: [
                {text: "Valeur d'achat", value: '0'}, {text: "Durée d'amortissement", value: '0'}, {text: "Montant total amorti", value: '2'}
            ],
            caracRight: [
                {text: "Numéro de série", value: '1111111'}, {text: "Date de mise en service", value: '01/01/2018'},
            ],
            isAuthorized: usrGetters.getScopeRight,
            uiParams: {
                cardElevation: 'elevation-2'
            },
            dialog: false,
            dialogsite: false,
            paginSite:  { total: 0 },
            paginEvent: { total: 0 },
            locDateFormat: dateLocale, // Format de date
            modalEvent : false,
            eveInfo: { eve_id: 0, eve_sta_id : 'EVE01', eve_table_id: 0, eve_xxx_id  : '' },
            items: [],
            events: [],
            typeState: cacheGetters.getTypes(),
            stateState: cacheGetters.getStates(),
            chartView: { area: false, specie: false, spec: false, areaRenew: false },
            chartSpeciestitle: 'Affectation courante',
            series: [],
            chartOptions: {
                chart: {
                    toolbar: { show: true, tools: { download: true, selection: true, zoom: true, zoomin: true, zoomout: true, pan: true } },
                    locales: apexLocale.locales, defaultLocale: "fr"
                },
                labels: [],
                responsive: [{
                    breakpoint: 480,
                    options: { chart: { width: 200 }, legend: { position: 'bottom' } }
                }],
                plotOptions: {
                    pie: { dataLabels: { offset: 0, minAngleToShowLabel: 0 }, }
                }
            },
            series2: [],
            chartOptions2: {
                chart: {
                    locales: apexLocale.locales, defaultLocale: "fr", //type: 'bar',
                    //stacked: true, toolbar: { show: true, tools: { download: true, selection: true, zoom: true, } }, zoom: { enabled: true },
                    events: { }, //click: (chartContext, config) => { console.log(chartContext); this.dialogsite = true }
                },
                colors:['#4CAF50', '#FF5722', '#2196F3', '#000'],
                dataLabels: { enabled: false },
                xaxis: { categories: [], }, //type : 'numeric',
                yaxis: [{
                    axisTicks: { show: true, },
                    axisBorder: { show: true, color: '#008FFB' },
                    title: { text: 'Surface (hectare)', }, 
                    tooltip: { enabled: true }
                }],
            },
            chart2Data: {},
            chart2Mode: 'h',
            series3: [
                //{ name: 'Cahier des charges', type: 'column', data: [] }, 
                //{ name: '-', type: 'line', data: [] }
            ],
            chartOptions3: {
                chart: { locales: apexLocale.locales, defaultLocale: "fr" },
                stroke: { curve: 'smooth' }, //width: [0, 4],
                //labels: [],
                xaxis: { type: 'datetime' },
                yaxis: [ { title: { text: 'Cahier des charges', }, }, ],
                dataLabels: { enabled: true, style: { fontSize: '10px', colors: ['#000'] }
                },
            },
            series4: [],
            chartOptions4: {
                chart: { locales: apexLocale.locales, defaultLocale: "fr" },
                colors: ['#FF9800', '#66DA26', '#546E7A', '#E91E63', '#FF9800'],
                plotOptions: { bar: { horizontal: false, dataLabels: { position: 'top', }, } },
                dataLabels: { enabled: true, offsetX: -6, style: { fontSize: '10px', colors: ['#000'] } },
                stroke: { show: true, colors: ['#fff'] },
                xaxis: { type: 'datetime', categories: [], },
            },

        }
    },
    watch: {
        filterView: function(){
            if( !this.filterView ){
                console.log('refresh')
            }
        }
    },
    mounted:function (){
        //this.fillData()
    },
    methods:{
        goRoute(route){
            this.$router.push({ path: '/' + route + '/' })
        },
        openEvent(id, table_id){
            this.eveInfo.eve_id     = id
            this.eveInfo.eve_xxx_id = table_id
            this.switchModal('modalEvent', 1)
        },
        switchModal(name, mode, refresh){
            name    = name || 'modalEvent'
            mode    = mode || 0
            refresh = refresh || false
            this[name] = mode
            if( refresh ){ 
                this.getEvent()
            }
        },
        getCacheType: cacheGetters.getTypes
    },
    components: {
        //'apexchart': VueApexCharts //LineChart
    }
}
</script>

<style>
  .small {
    max-width: 600px;
  }
</style>