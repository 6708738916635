<template>
<v-container fluid class="pa-0">
    <v-toolbar class="elevation-1" color="barrinfo">
        <v-toolbar-title >Liste des prestations de maintenance</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-tooltip bottom>
            <template v-slot:activator="{ on }">
                <v-btn rounded outlined depressed text @click="downloadSite()" color="primary" class="mr-2 white--text" v-on="on">
                    <v-icon dark>mdi-cloud-download-outline</v-icon><span class="hidden-sm-and-down"></span>
                </v-btn>
            </template>
            <span>Télécharger la liste</span>
        </v-tooltip>
<!--        <v-btn v-if="isAuthorized( 'trackingsheetedit', 'edit' )" rounded to="/sheet/ajouter" color="success" depressed class="white--text">
            <v-icon left dark>mdi-plus-circle</v-icon>
            <span class="hidden-sm-and-down">Créer</span>
        </v-btn>
-->
    </v-toolbar>
    <v-text-field append-icon="mdi-magnify" label="Recherche"
        solo clearable single-line hide-details v-model="search" @blur="saveSearchToCache()" class="ma-4 pa-0">
    </v-text-field>
    <v-card class="ma-4 mb-2">
    <v-data-table ref="sheetTable" v-bind:headers="headers" v-bind:items="items" v-bind:search="search" @current-items="getFilteredItems"
        :page.sync="pagination.page" :items-per-page="pagination.itemsPerPage" @page-count="pagination.pageCount = $event"
        footer-props.items-per-page-text="Lignes par page" hide-default-footer>
        <template v-slot:body="{ items }">
        <tbody>
        <tr v-for="item in items" :key="item.pre_id" @click="openSite(item.trs_id)" style="cursor:pointer;">
            <td class="text-xs-left">{{ item.pre_date }}</td>
            <td class="text-xs-left">{{ item.pre_date_plan }}</td>
            <td class="text-xs-left">{{ item.pre_index }}</td>
            <td class="text-xs-left">{{ item.pre_ref }}</td>
            <td class="text-xs-left">{{ item.equ_name }}</td>
            <td class="text-xs-left">{{ item.pre_date_last }}</td>
            <td class="text-xs-left">{{ item.pre_index_last }}</td>
            <td class="text-xs-left">{{ item.ht1 }}</td>
            <td class="text-xs-left">{{ item.ht2 }}</td>
            <td class="text-xs-left">{{ item.ht3 }}</td>
        </tr>
        </tbody>
<!--        <tfoot style="background-color:#E3F2FD;">
            <tr>
                <td></td><td></td><td></td><td></td><td></td><td></td>
                <td class="text-xs-left">{{ calcTotalArea }}</td>
                <td></td>
            </tr>
        </tfoot>
-->
        </template>
        <template slot="no-data">
        <v-alert :value="true" color="error" icon="mdi-alert">
            Aucune fiche
        </v-alert>
        </template>
    </v-data-table>
    </v-card>
    <v-layout wrap>
        <v-flex xs6 class="text-left pl-8">
            <span v-show="pagination.totalFiltered">{{pagination.totalFiltered}} sur </span>
            <span>{{pagination.totalItems}} résultats</span>
        </v-flex>
        <v-flex xs6 class="text-right pr-0">
            <v-pagination v-model="pagination.page" :length="pagination.pageCount" :total-visible="7" ></v-pagination> <!-- :length="pages" @input="handlePage" -->
        </v-flex>
    </v-layout>
</v-container>
</template>

<script>
import dateLocale from '../services/dateLocale'
import { cacheGetters, cacheMutations } from "../store/cache.module"
import { trsObj }  from '../mixins/trs_obj.js'

import { usrGetters }   from "../store/user.module"

export default {
    name: 'trackingsheet',
    props: ['toast', 'filterView'],
    mixins: [ trsObj ],
    data () {
        return {
            max25chars: (v) => v.length <= 25 || 'Trop de charactères',
            tmp: '',
            search: '',
            pagination: { page: 1, totalItems: 0, itemsPerPage: 12, totalPage: 1, limit: 0, pageCount: 0, totalFiltered: 0 },
            headers: [
            { text: 'Date début travaux', align: 'left',          value: 'pre_date'},
            { text: 'Date fin travaux',    align: 'left',         value: 'pre_date_plan'}, //trs_mc2_code
            { text: 'Index', align: 'left',                value: 'pre_index'},
            { text: 'Prestation réalisée',               value: 'pre_ref' },
            { text: 'Nature', align: 'left',             value: 'equ_name' },
            { text: 'Prestataire', align: 'left',value: 'pre_date_last' },
            { text: 'HT', align: 'left',      value: 'pre_index_last' },
            { text: 'Prest HT',      align: 'left',          value: 'pre_top' },
            { text: 'MD HT',      align: 'left',          value: 'pre_top' },
            { text: 'Article HT',      align: 'left',               value: 'pre_id' },
            ],
            items: [
{ pre_id: 1, pre_date : '20/10/2020', pre_date_plan : '23/10/2020', pre_index : '2145 H', pre_ref : 'REMPLT PNEUMATIQUE',  equ_name : 'ENTRETIEN', pre_date_last : 'EUROMASTER',  pre_index_last : '145 €',       ht3: '45 €', ht2: '100 €', ht1: '145 €',       pre_top : 0, },
{ pre_id: 2, pre_date : '23/11/2020', pre_date_plan : '23/11/2020', pre_index : '1259 H', pre_ref : 'REPARATION GYROPHARE',equ_name : 'CASSE',     pre_date_last : 'ATE ANDILLY', pre_index_last : '175 €',       ht3: '25 €', ht2: '150 €', ht1: '',            pre_top : 1, },
{ pre_id: 3, pre_date : '16/09/2020', pre_date_plan : '23/09/2020', pre_index : '3108 H', pre_ref : 'LISTE C',             equ_name : 'ENTRETIEN', pre_date_last : 'SOLVERT',     pre_index_last : '16 189,30 €', ht3: '12 969,30 €', ht2: '3 220,00 €', ht1: '16 189,30 €', pre_top : 1, },
            ],
            filteredItems: [],
            locDateFormat: dateLocale, // Format de date
            enableAdd: true,
            modalEvent : false,
            isAuthorized: usrGetters.getScopeRight,
        }
    },
    watch: {
        filterView: function(){
            if( !this.filterView ){
                this.getPreventive()
            }
        }
    },
    computed: {
        pages () {
            if (this.pagination.totalItems == null || this.pagination.totalItems == null ) return 0
            return Math.ceil(this.pagination.totalItems / this.pagination.itemsPerPage)
        },
        calcTotalArea() {
            let total = 0
            this.items.forEach(element => {
                total += element.trs_area_plot
            })
            if( this.search && this.search.length > 0 ){
                return ''
            } else {
                return Math.round(total * 100) / 100
            }
        }
    },
    mounted: function (){
        this.search = cacheGetters.getSearchBarItem('preventive') || ''
        //this.getPreventive()
    },
    methods: {
        getPreventive(){
            this.items = []
            this.pagination.page = 1
            this.getTrackingSheetIndex(1, false, '&extended=withhisto').then( () => {
                if( this.metaTrackingSheet.pagination.total !== undefined && this.metaTrackingSheet.pagination.total !== '0' ){
                    this.pagination.totalItems  = parseInt(this.metaTrackingSheet.pagination.total)
                } else {
                    this.pagination.totalItems = 1
                }
                this.items = this.listTrackingSheet
            })
        },
        saveVersion(){
                this.getEvent()
                this.enableAdd = true
        },
        openSite(trs_id){
            this.$router.push({ path: '/trackingsheet/sheet/' + trs_id })
        },
        downloadSite(){
            let searchCriteria = this.getTrackingSheetFilter()
            this.$http.customRequest({ method: 'get', url: '/report/trackingsheet/' + searchCriteria, responseType: 'blob' } )
            .then( (response) => {
                this.$download(response.data, "rapport_1.xlsx", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet")
            })
        },
        handlePage(index){
            this.pagination.limit = this.pagination.rowsPerPage * (index - 1)
            //this.pagination.page  = (index - 1)
            this.getPreventive()
        },
        getFilteredItems(e){
            let totalFiltered = e.length
            if( totalFiltered >= this.pagination.itemsPerPage ){
                this.pagination.totalFiltered = 0
            } else {
                this.pagination.totalFiltered = totalFiltered
            }
        },
        saveSearchToCache(){
            cacheMutations.setSearchBarItem('preventive', this.search)
        },
        getCacheType: cacheGetters.getTypes
    },
    components: {
        
    }
}
</script>

<style lang="css">
.v-pagination{
    width: 450px;
}
</style>