<template>
<v-container fluid>
<v-row>
    <v-col cols="12" md="8">
        <v-card :class="uiParams.cardElevation" tile flat>
            <v-toolbar flat color="#f6f8f8" prominent height="50px">
                <v-toolbar-title><v-icon>mdi-chart-bar</v-icon> Informations de base</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn color="primary" href="/#/equipment/0">Créer du matériel</v-btn>
                <!-- <v-btn icon @click="fillData()"><v-icon>mdi-refresh</v-icon></v-btn> -->
            </v-toolbar>
            <v-divider></v-divider>
            <v-card-text>
                <v-layout wrap style="background-color:white;" class="mt-3 pa-2 elevation-1">
                    <v-flex xs6 style="border-right:1px solid #E0E0E0;">
                        <v-list dense>
                            <template v-for="(itemLeft, indexLeft) in baseLeft">
                            <v-list-item :key="indexLeft">
                                <v-list-item-content><v-list-item-title>{{itemLeft.text}}</v-list-item-title></v-list-item-content>
                                <v-list-item-action ><v-list-item-action-text>{{itemLeft.value}}</v-list-item-action-text>
                                </v-list-item-action>
                            </v-list-item>
                            </template>
                        </v-list>
                    </v-flex>
                    <v-flex xs6>
                        <v-list dense>
                            <template v-for="(itemRight, indexRight) in baseRight">
                            <v-list-item :key="indexRight">
                                <v-list-item-content><v-list-item-title>{{itemRight.text}}</v-list-item-title></v-list-item-content>
                                <v-list-item-action ><v-list-item-action-text>{{itemRight.value}}</v-list-item-action-text>
                                </v-list-item-action>
                            </v-list-item>
                            </template>
                        </v-list>
                    </v-flex>
                </v-layout>
            </v-card-text>
        </v-card>
    </v-col>
    <v-col cols="12" md="4">
        <v-card :class="uiParams.cardElevation" tile flat>
            <v-toolbar flat color="#f6f8f8" prominent height="50px">
                <v-toolbar-title><v-icon>mdi-chart-bar</v-icon> {{ chartSpeciestitle }}</v-toolbar-title>
                <v-spacer></v-spacer>
                <!-- <v-btn small :text="(chart2Mode !== 'ml')" :outlined="(chart2Mode !== 'ml')" @click="computeSpecieByMode('ml')" :color="(chart2Mode == 'ml') ? 'green' : 'grey'" class="mt-1"> Scanner</v-btn>-->
            </v-toolbar>
            <v-divider></v-divider>
            <v-img height="250"
            src="https://tondeuse-et-compagnie.fr/15604-superlarge_default/debroussailleuse-thermique-43-cm3-lea-avec-harnais-double-et-couteau-3-dents-le25428bp.jpg">
            </v-img>
            <v-card-title>Ref: 45687796</v-card-title>
            <v-card-text>
                <v-row align="center" class="mx-0" >
                <v-progress-linear value="15"></v-progress-linear>
                <div class="grey--text ml-4">15% (413 h)</div>
                </v-row>
                <div class="my-4 subtitle-1"></div>
                <div>
                    • Index compteur: 321111<br />
                    • Service: ENTRETIEN<br />
                    • Secteur: NORD<br />
                    • Dépôt: ANDILLY
                </div>
            </v-card-text>
            <v-divider class="mx-4"></v-divider>
            <v-card-title>Changement: 01/02/2021</v-card-title>
            <v-card-text>
                <v-chip-group active-class="deep-purple accent-4 white--text" column>
                <v-chip>8:30</v-chip><v-chip>11:35</v-chip>
                </v-chip-group>
            </v-card-text>
            <v-card-actions>
            <v-btn color="red lighten-2" text>STOP</v-btn>
            <v-spacer></v-spacer>
            <v-btn color="primary" href="/#/equipment">SCANNER</v-btn>
            </v-card-actions>
        </v-card>
    </v-col>
    <v-col cols="12" md="8">
        <v-card :class="uiParams.cardElevation" tile flat v-if="isAuthorized( 'dashboard', 'lastsheet' )">
        <v-toolbar flat color="#f6f8f8" prominent height="50px">
            <v-toolbar-title>
                <v-icon>mdi-format-list-checks</v-icon>
                Caractéristiques
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <!-- <v-btn icon><v-icon>mdi-restore</v-icon></v-btn> -->
        </v-toolbar>
        <v-divider></v-divider>
            <v-card-text>
                <v-layout wrap style="background-color:white;" class="mt-3 pa-2 elevation-1">
                    <v-flex xs6 style="border-right:1px solid #E0E0E0;">
                        <v-list dense>
                            <template v-for="(itemLeft, indexLeft) in caracLeft">
                            <v-list-item :key="indexLeft">
                                <v-list-item-content><v-list-item-title>{{itemLeft.text}}</v-list-item-title></v-list-item-content>
                                <v-list-item-action ><v-list-item-action-text>{{itemLeft.value}}</v-list-item-action-text>
                                </v-list-item-action>
                            </v-list-item>
                            </template>
                        </v-list>
                    </v-flex>
                    <v-flex xs6>
                        <v-list dense>
                            <template v-for="(itemRight, indexRight) in caracRight">
                            <v-list-item :key="indexRight">
                                <v-list-item-content><v-list-item-title>{{itemRight.text}}</v-list-item-title></v-list-item-content>
                                <v-list-item-action ><v-list-item-action-text>{{itemRight.value}}</v-list-item-action-text>
                                </v-list-item-action>
                            </v-list-item>
                            </template>
                        </v-list>
                    </v-flex>
                </v-layout>
            </v-card-text>
        <v-card-actions style="cursor:pointer;" @click="goRoute('preventive')">
            <v-icon>mdi-eye-settings-outline</v-icon><span class="ml-3">Liste</span>
            <v-spacer></v-spacer>
            <v-chip small color="light-green" text-color="white">{{ paginSite.total }}</v-chip>
        </v-card-actions>
        </v-card>
    </v-col>
    <v-col cols="12" md="4">
        <v-card :class="uiParams.cardElevation" tile flat v-if="isAuthorized( 'dashboard', 'lastevent' )">
            <v-toolbar flat color="#f6f8f8" prominent height="50px">
                <v-toolbar-title>
                    <v-icon>mdi-information-outline</v-icon>
                    Evènements
                </v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn icon @click="dialog = true;">
                    <v-icon>mdi-circle-edit-outline</v-icon>
                </v-btn>
            </v-toolbar>
            <v-divider></v-divider>
            <v-list class="pb-0" two-line>
                <template v-for="(item, index) in events">
                    <v-list-item :key="index" class="pb-0">
                        <v-list-item-content>
                            <v-list-item-title :style="'color:' + item.sta_color + ';'">
                                <span class="caption grey--text">{{ item.eve_description }}</span>
                            </v-list-item-title>
                            <v-list-item-subtitle class="caption font-italic">{{ item.eve_cost_value }}</v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-action>
                            <span class="caption">{{ locDateFormat.formatLocale(item.eve_date_start, 'fr') }}</span>
                            <v-chip small label outlined>{{ typeState[item.eve_typ_id.substring(0,3)][item.eve_typ_id].typ_name }}</v-chip>
                        </v-list-item-action>
                    </v-list-item>
                    <v-divider :key="'D-' + index"></v-divider>
                </template>
            </v-list>
            <v-card-actions>
                <span class="ml-3">-</span>
                <v-spacer></v-spacer>
                <v-chip small color="light-green" text-color="white">{{ paginEvent.total }}</v-chip>
            </v-card-actions>
        </v-card>
    </v-col>
</v-row>

<v-layout row justify-center>
    <v-dialog v-model="dialogsite" hide-overlay persistent max-width="600px">
        <v-card color="grey lighten-4" min-width="350px" flat >
        <v-toolbar color="primary" dark>
            <v-btn icon><v-icon>mdi-chevron-down</v-icon></v-btn>
            <v-toolbar-title>Sites: </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon color="" @click.native="dialogsite = false;"><v-icon>mdi-close</v-icon></v-btn>
        </v-toolbar>       
        <v-card-text>
            <v-textarea solo label="Informations utiles" hint="Ajouter modifier des informations"></v-textarea>
        </v-card-text>
        </v-card>
    </v-dialog>
</v-layout>
</v-container>
</template>

<script>
import dateLocale from '../services/dateLocale';
//import VueApexCharts from 'vue-apexcharts'
import { cacheGetters } from "../store/cache.module"
import { trsObj }  from '../mixins/trs_obj.js'
import apexLocale  from '../plugins/apexchart.js'
import { usrGetters }   from "../store/user.module"

export default {
    name: 'dashboard',
    props: ['toast', 'filterView'],
    mixins: [ trsObj ],
    data () {
        return {
            baseLeft: [
            {text: "Matricule",    value: '120001'}, {text: "Groupe principal", value: 'TONDEUSE AUTOPORTEE'},
            {text: "Groupe",       value: ''}, {text: "Sous-groupe", value: ''},
            {text: "Catégorie",    value: ''}, {text: "Mode d'Acquisition", value: ''},
            {text: "Propriétaire", value: 'PINSON'}, {text: "Equipement", value: ''},
            ],
            baseRight: [
                {text: "Immatriculation", value: ''}, {text: "Marque", value: 'TORO'},
                {text: "Modèle", value: '7200'}, {text: "Version", value: 'GROUNMASTER'},
                {text: "Energie", value: 'DIESEL'}, {text: "Marqueur", value: 'TOMTOM'},
                {text: "Activité compteur Année -1 ", value: '28h'}, {text: "Index compteur ", value: '2145h'},
            ],
            caracLeft: [
                {text: "Valeur d'achat", value: '0'}, {text: "Durée d'amortissement", value: '0'}, {text: "Montant total amorti", value: '2'}
            ],
            caracRight: [
                {text: "Numéro de série", value: '1111111'}, {text: "Date de mise en service", value: '01/01/2018'},
            ],
            isAuthorized: usrGetters.getScopeRight,
            uiParams: {
                cardElevation: 'elevation-2'
            },
            saveProgress: false,
            dialog: false,
            dialogsite: false,
            paginSite:  { total: 0 },
            paginEvent: { total: 0 },
            locDateFormat: dateLocale, // Format de date
            modalEvent : false,
            eveInfo: { eve_id: 0, eve_sta_id : 'EVE01', eve_table_id: 0, eve_xxx_id  : '' },
            items: [],
            events: [],
            typeState: cacheGetters.getTypes(),
            stateState: cacheGetters.getStates(),
            chartView: { area: false, specie: false, spec: false, areaRenew: false },
            chartSpeciestitle: 'Affectation courante',
            series: [],
            chartOptions: {
                chart: {
                    toolbar: { show: true, tools: { download: true, selection: true, zoom: true, zoomin: true, zoomout: true, pan: true } },
                    locales: apexLocale.locales, defaultLocale: "fr"
                },
                labels: [],
                responsive: [{
                    breakpoint: 480,
                    options: { chart: { width: 200 }, legend: { position: 'bottom' } }
                }],
                plotOptions: {
                    pie: { dataLabels: { offset: 0, minAngleToShowLabel: 0 }, }
                }
            },
            series2: [],
            chartOptions2: {
                chart: {
                    locales: apexLocale.locales, defaultLocale: "fr", //type: 'bar',
                    //stacked: true, toolbar: { show: true, tools: { download: true, selection: true, zoom: true, } }, zoom: { enabled: true },
                    events: { }, //click: (chartContext, config) => { console.log(chartContext); this.dialogsite = true }
                },
                colors:['#4CAF50', '#FF5722', '#2196F3', '#000'],
                dataLabels: { enabled: false },
                xaxis: { categories: [], }, //type : 'numeric',
                yaxis: [{
                    axisTicks: { show: true, },
                    axisBorder: { show: true, color: '#008FFB' },
                    title: { text: 'Surface (hectare)', }, 
                    tooltip: { enabled: true }
                }],
            },
            chart2Data: {},
            chart2Mode: 'h',
            series3: [
                //{ name: 'Cahier des charges', type: 'column', data: [] }, 
                //{ name: '-', type: 'line', data: [] }
            ],
            chartOptions3: {
                chart: { locales: apexLocale.locales, defaultLocale: "fr" },
                stroke: { curve: 'smooth' }, //width: [0, 4],
                //labels: [],
                xaxis: { type: 'datetime' },
                yaxis: [ { title: { text: 'Cahier des charges', }, }, ],
                dataLabels: { enabled: true, style: { fontSize: '10px', colors: ['#000'] }
                },
            },
            series4: [],
            chartOptions4: {
                chart: { locales: apexLocale.locales, defaultLocale: "fr" },
                colors: ['#FF9800', '#66DA26', '#546E7A', '#E91E63', '#FF9800'],
                plotOptions: { bar: { horizontal: false, dataLabels: { position: 'top', }, } },
                dataLabels: { enabled: true, offsetX: -6, style: { fontSize: '10px', colors: ['#000'] } },
                stroke: { show: true, colors: ['#fff'] },
                xaxis: { type: 'datetime', categories: [], },
            },

        }
    },
    watch: {
        filterView: function(){
            if( !this.filterView ){
                console.log('refresh')
            }
        }
    },
    mounted:function (){
        //this.fillData()
    },
    methods:{
        getEvent(){
            this.$http.get( '/events/?page=1&per_page=3&order_by=eve_id&order_direction=desc' ).then( (response) => {
                this.events = response.data.data
                if( response.data.meta ){
                    this.paginEvent = Object.assign({}, this.paginEvent, response.data.meta.pagination)
                }
            })
        },
        goRoute(route){
            this.$router.push({ path: '/' + route + '/' })
        },
        openEvent(id, table_id){
            this.eveInfo.eve_id     = id
            this.eveInfo.eve_xxx_id = table_id
            this.switchModal('modalEvent', 1)
        },
        switchModal(name, mode, refresh){
            name    = name || 'modalEvent'
            mode    = mode || 0
            refresh = refresh || false
            this[name] = mode
            if( refresh ){ 
                this.getEvent()
            }
        },
        totalColor( total ){
            if( total <= 50 ){
                return 'red'
            } else if( total <= 80 ){
                return 'warning'
            } else {
                return 'green'
            }
        },
        fillData () {
            this.datacollection = {
                labels: [this.getRandomInt(), this.getRandomInt()],
                datasets: [
                    {
                        label: 'Donnée 1',
                        backgroundColor: '#f87979',
                        data: [this.getRandomInt(), this.getRandomInt()]
                    }, {
                        label: 'Donnée 2',
                        backgroundColor: '#009688',
                        data: [this.getRandomInt(), this.getRandomInt()]
                    }
                ]
            }
        },
        getRandomInt () {
            return Math.floor(Math.random() * (20 - 5 + 1)) + 5
        },
        getCacheType: cacheGetters.getTypes
    },
    components: {
        //'apexchart': VueApexCharts //LineChart
    }
}
</script>

<style>
  .small {
    max-width: 600px;
  }
</style>