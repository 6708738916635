<template>
<v-container fluid class="pa-3">
    <v-card fluid class="ma-2 pa-5 elevation-2">
        <v-layout row wrap>
        <v-flex xs6 class="pa-2">
            <v-text-field label="Date de début travaux"    v-model="form.typ_id" ></v-text-field>
            <v-text-field label="Index compteur"  v-model="form.typ_entity"></v-text-field>
            <v-select :items="['JACQUES']" item-value="value" v-model="form.pre_nature" label="Intervenant" single-line></v-select>
            <v-select :items="['ATE ANDILY']" item-value="value" v-model="form.pre_nature" label="Prestataire" single-line></v-select>
            <v-text-field label="Imputation Conducteur Tvx" ></v-text-field>
        </v-flex>
        <v-flex xs6 class="pa-2">
            <v-text-field label="Date de fin travaux"    v-model="form.typ_id" ></v-text-field>
            <v-text-field label="Prestation réalisée"    v-model="form.typ_id" ></v-text-field>
            <v-select :items="['CASSE']" item-value="value" v-model="form.pre_nature" label="Nature prestation" single-line></v-select>
            <v-text-field label="Main d'œuvre HT"    v-model="form.typ_id" ></v-text-field>
            <v-text-field label="Article HT"    v-model="form.pre_artht" ></v-text-field>    
        </v-flex>
        </v-layout>
        <div class="text-right">
            <v-btn color="success" @click.native="saveForm()">
            Enregistrer
            </v-btn>
            <v-btn color="primmary" href="/#/types/">
            Retour
            </v-btn>
        </div>
    </v-card>
</v-container>
</template>

<script>
import dateLocale from '../services/dateLocale'
import { cacheGetters } from "../store/cache.module"

export default {
    name: 'site_edit',
    props: ['toast', 'objTrackingSheet', 'routeState'], 
    data: function () {
        return {
            form: { pre_nature: 0, pre_artht: 25 },
            loading: { getVersion: false },
            validFrom: true,
            locDateFormat: dateLocale, // Format de date
            panel : 0,
            noticeHeaders: [
                { text: ' ',          type:'text',  width: '32' },
                { text: 'En demande', type:'value', width: '20', active: 1 },
                { text: 'Validé',     type:'value', width: '20', active: 2 },
                { text: 'Non validé', type:'value', width: '20', active: 3 },
                { text: '',           type:'date',  width: '8',  active: 2 },
            ],
            noticeProp: cacheGetters.getTypesByTag('TST', 'PRO'),
            noticeDate: {},
            // data brute
            footer: { 'items-per-page-all-text':"tout", 'items-per-page-text':'Ligne par page' },
            pagination: { totalItems: 0, rowsPerPage: 10, rowsPerItem: [15,25,35,{text:'Tout',value:-1}] },
            contactHeader: [
                { text: 'Type',    value: 'typ_name', sortable: true },
                { text: 'Contact', value: 'ctt_middlename', sortable: true },
            ],
            contactItems: [],
            dataHeader: [
                { text: 'Type',         value: 'typ_name', sortable: true },
                { text: 'Etat',         value: 'trs_state_numeric', sortable: true },
                { text: 'Date',         value: 'tst_date', sortable: true },
                { text: 'Valeur',       value: 'tst_value', sortable: true },
                { text: 'Valeur2',      value: 'tst_value_two', sortable: true },
                { text: 'Commentaire',  value: 'tst_note', sortable: true },
            ],
            dataItems: [],
            typeState: cacheGetters.getTypes()
        }
    },
    computed: {

    },
    mounted: function (){
        //setTimeout(() => {
        //    this.objTrackingSheet._data['trackingstates']['TST01'].tst_date = new Date().toISOString().substring(0, 7)
        //}, 2000)
    },
    methods: {
        refreshContactItems(){
                if( this.contactItems.length == 0 ){
                    for(var prop in this.objTrackingSheet._data['contacts']){
                        let element = this.objTrackingSheet._data['contacts'][prop]
                        let contact = element.map( (elem) => { return elem.ctt_middlename; }).join(",")
                        if( contact.length > 0 ){
                            this.contactItems.push( { typ_name: this.typeState['TSC'][prop].typ_name, typ_id:prop, ctt_middlename: contact } )
                        }
                    }
                }
        },
        refreshDataItems(){
            if( this.dataItems.length == 0 ){
                var note    = ''
                for(var prop in this.objTrackingSheet._data['trackingstates']){
                        let element = this.objTrackingSheet._data['trackingstates'][prop]
                        try{
                            note  = ''
                            let jsonData  = JSON.parse( element.tst_note )
                            for(var propJ in jsonData){
                                note += ' / ' + jsonData[propJ]
                            }
                        } catch {
                            note  = ''
                        }
                        this.dataItems.push( { typ_name: this.typeState['TST'][prop].typ_name, typ_id:prop, 
                            trs_state_numeric: element.tst_state_numeric,
                            tst_date: element.tst_date,
                            tst_value: element.tst_value,
                            tst_value_two: element.tst_value_two,
                            tst_note: note,
                        })
                }
            }
        },
        changeState(prop, state){
            if( this.routeState.edit ){
                this.objTrackingSheet._data['trackingstates'][prop].tst_state_numeric = state
                this.$emit('update:objTrackingSheet', this.objTrackingSheet)
            }
        },
        saveForm(){


        }
    },
    components: { }
}
</script>