<template>
<v-container fluid class="pa-1">
    <v-toolbar class="elevation-0" height="48px">
        <v-toolbar-title >Liste des fichiers</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn v-if="enableAdd && isAuthorized( 'document', 'edit' )" 
            outlined rounded small :color="(routeState.view) ? 'grey' : 'green'" @click="openDocument(0)" :disabled="!routeState.edit">
            Ajouter<v-icon>mdi-playlist-edit</v-icon>
        </v-btn>
    </v-toolbar>
    <v-data-table v-bind:headers="headers" :items="items" item-key="sta_id"
        items-per-page-text="Lignes par page" :items-per-page-options="pagination.rowsPerItem" :loading="loading.getDocument"> 
        <template v-slot:item.doc_created_at="{ item }" :color="'grey'">
            <v-chip label outlined >{{ locDateFormat.formatLocale( item.doc_created_at, 'fr' ) }}</v-chip>
        </template>
        <template v-slot:item.doc_name="{ item }" :color="'grey'">
            <span class="d-inline-block text-truncate" style="max-width: 400px;">
            {{ item.doc_name }}
            </span>
        </template>
        <template v-slot:item.doc_id="{ item }" :color="'grey'">
            <v-chip label small outlined title="Télécharger" @click="downloadDocument(item.doc_id)">
                <v-icon left color='grey'>mdi-file-download</v-icon>
            </v-chip>
            <v-chip v-if="isAuthorized( 'document', 'edit' )" label small outlined title="Editer" @click="editDocument(item.doc_id)">
                <v-icon left color='orange'>mdi-pencil</v-icon>
            </v-chip>
            <v-chip v-if="isAuthorized( 'document', 'delete' )" label small outlined title="Supprimer" @click="deleteDocument(item.doc_id)">
                <v-icon left color='red'>mdi-delete-forever</v-icon>
            </v-chip>
        </template>
        <template slot="pageText" slot-scope="{ pageStart, pageStop }">
        De {{ pageStart }} à {{ pageStop }}
        </template>
        <template slot="no-data">
        <v-alert :value="( !loading.getDocument )" color="error" icon="mdi-alert">
            Aucune fichier, ajoutez un nouveau fichier
        </v-alert>
        </template>
    </v-data-table>

    <v-dialog v-model="modalLayer" max-width="900" >
        <!-- <tab-layer v-if="modalLayer" :doc_id="doc_id" :toast="toast" :switchModal="switchModal"></tab-layer> -->
    </v-dialog>
    <v-dialog v-model="modalDocument" max-width="900" >
        <v-card color="grey lighten-4" min-width="350px" flat >
        <v-toolbar color="primary" dark class="elevation-1" height="50px">
            <v-btn icon><v-icon>mdi-chevron-down</v-icon></v-btn>
            <v-toolbar-title>Document</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon color="" @click.native="switchModal('modalDocument', 0)"><v-icon>mdi-close</v-icon></v-btn>
        </v-toolbar>
        <v-card-text class="mt-2">
            <edit-document class="elevation-2" 
                v-if="modalDocument" :toast="toast" :switchModal="switchModal"
                :xxxId="doc_xxx_id" :docId="docInfo.doc_id" :docTable="table_id" :docType="'DOC'">
            </edit-document>
        </v-card-text>
        </v-card>
    </v-dialog>
</v-container>
</template>

<script>
import dateLocale   from '../services/dateLocale';
import editDocument from '../components/Edit_document.vue'
import { usrGetters }   from "../store/user.module"

export default {
    name: 'tab_document',
    props: ['toast', 'table_id', 'doc_xxx_id', 'routeState'],
    data: function () {
      return {
            loading: { uploadBtn: false, getDocument: false },         // Enregistrement en cours...
            pagination: { totalItems: 0, rowsPerPage: 10, rowsPerItem: [15,25,35,{text:'Tout',value:-1}] },
            headers: [
            { text: 'Nom', align: 'left', value: 'doc_name'},
            { text: 'Date de création', align: 'left', value: 'doc_created_at'},
            { text: 'Télécharger', value: 'doc_id' }
            ],
            items: [],
            locDateFormat: dateLocale,
            enableAdd: true,
            addItem: {
                doc_id: 0 , doc_xxx_id: this.doc_xxx_id, doc_table_id: 0, doc_sta_id: 'GEN01',
                doc_filename: '', doc_name: '', doc_description: '',
                _method: 'POST', _path: 'document'
            },
            modalLayer : false,
            docInfo: { doc_id: 0, doc_sta_id : 'DOC01', doc_table_id: this.table_id, doc_xxx_id  : this.doc_xxx_id },
            modalDocument: false,
            isAuthorized: usrGetters.getScopeRight
      }
    },
    mounted: function (){
        //this.getDocument()
    },
    methods: {
        getDocument(){
            //this.items = []
            this.loading.getDocument = true
            var searchCriteria = '?per_page=false' //page='+ this.pagination.page +'&
            searchCriteria    += '&doc_xxx_id=' +  this.doc_xxx_id + '&doc_table_id=' + this.table_id
            this.$http.get( '/documents/' + searchCriteria ).then( (response) => {
                if( response.data.meta.pagination.total !== undefined && response.data.meta.pagination.total !== '0' ){
                    this.pagination.totalItems  = parseInt(response.data.meta.pagination.total)
                } else {
                    this.pagination.totalItems = 1
                }
                if( Object.prototype.toString.call( response.data.data ) === '[object Array]' ) {
                    this.items = response.data.data
                }
                this.loading.getDocument = false
            })
        },
        addLineFile() {
            this.enableAdd = false
            this.items.push({
                doc_id      : 0,
                doc_filename: '',
                doc_name    : '',
                doc_description: ''
            })
        },
        openDocument(id){
            this.docInfo.doc_id = id
            this.switchModal('modalDocument', 1)
        },
        editDocument(id){
            this.docInfo.doc_id = id
            this.switchModal('modalDocument', 1)
        },
        openAssocModal(id){
            if( id !== undefined && id > 0 ){
                this.switchModal('modalLayer', 1)
            }
        },
        deleteDocument( docid ){
            this.$root.$confirm('Supprimer', 'Confirmer la suppression de ce document', { color: 'red', width: 400 }).then((confirm) => {
                if( confirm ){
                    this.$http.delete( 'documents/' +  docid).then( () => {
                        this.toast({ color: 'error', top:false, bottom:true, right:false, left:true, text:'Document supprimée !' })
                        this.getDocument()
                    })
                }
            })
        },
        downloadDocument(doc_id){
            this.$http.customRequest({ method: 'get', url: 'documents/' + doc_id + '/download', responseType: 'blob' } )
            .then( (response) => {
                let filename      = 'd.pdf'
                let filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                let matches = filenameRegex.exec(response.headers['content-disposition']);
                if (matches != null && matches[1]) { 
                    filename = matches[1].replace(/['"]/g, '');
                }
                if( response.status == 204 ){
                    this.toast({ color: 'warning', text: 'Ce fichier n\'existe pas' })
                } else {
                    this.$download(response.data, filename, response.headers['content-type'])
                }
            }).catch( (error) => {
                this.toast({ color: 'error', text: error })
            })
        },
        switchModal(name, mode, refresh){
            name    = name || 'modalDocument'
            mode    = mode || 0
            refresh = refresh || false
            this[name] = mode
            //if( mode == 0 ){
            //    this.doc_xxx_id = 0
            //}
            if( refresh ){
                this.getDocument()
            }
        }
    },
    components: {
        'edit-document'   : editDocument
    }
}
</script>